import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["formContainer"];

  connect() {
    this.bindFormSubmit()
  }

  bindFormSubmit() {
    const form = $(this.formContainerTarget).find("form")
    const formContainer = this.formContainerTarget

    form.submit(function(event) {
      event.preventDefault()

      $.post(
        form.attr("action"),
        form.serialize()
      ).always(function(data) {
        $(formContainer).html(data)
      })
    })
  }
}
