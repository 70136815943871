import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["incompleteSection", "submitButton", "reason", "comment"]

  statusChanged(event) {
    const value = event.target.value

    if (value === "incomplete") {
      $(this.incompleteSectionTarget).removeClass("d-none")
    } else {
      $(this.incompleteSectionTarget).addClass("d-none")
    }
  }

  reasonsChange() {
    const anyReasonChecked = this.reasonTargets.some(reason => {
      return $(reason).prop("checked")
    })
    const commentBlank = $(this.commentTarget).val().trim().length === 0

    if (anyReasonChecked && commentBlank) {
      $(this.submitButtonTarget).prop("disabled", true)
    } else {
      $(this.submitButtonTarget).prop("disabled", false)
    }
  }
}
