import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["self"];

  connect() {
    $(this.selfTarget).select2({
      placeholder: "Search for a client...",
      width: "100%",
      ajax: {
        url: "/clients/search",
        delay: 250,
        type: "GET",
        dataType: "json",
        minimumInputLength: 3,
        data: function (params) {
          return { search_query: params.term };
        },
        processResults: function (data) {
          // Transforms the top-level key of the response object from 'items' to 'results'

          var results = [];
          data.results.forEach(function (record) {
            var email = record.email || "No email";
            results.push({
              id: record.client_id,
              text: record.full_name + "(" + email + ")",
            });
          });

          return {
            results: results,
          };
        },
      },
    });
  }
}
