import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form"];

  connect () {
    $('.js-select2').select2({
      tags: true,
      theme: "bootstrap"
    });
  }
}
