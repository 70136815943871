// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
const contextComponents = require.context("../../components", true, /_controller\.js$/)
application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(contextComponents)
  )
)

import { Dropdown } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown)

import { Slideover } from "tailwindcss-stimulus-components"
application.register('slideover', Slideover)

import { Autocomplete } from "stimulus-autocomplete"
application.register('autocomplete', Autocomplete)

import { Popover } from "tailwindcss-stimulus-components"
application.register('popover', Popover)
