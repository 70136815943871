import { Controller } from "stimulus"

const GROUP_ALL = "group_all";
const GROUP_NONE = "group_none";

export default class extends Controller {
  static targets = ["count", "checkbox", "groupCheckbox"];

  connect() {
    this.setCount();
  }

  clickedCheckbox(event) {
    if (event.currentTarget.value === GROUP_ALL) {
      if (event.currentTarget.checked) {
        this.checkAll();
      } else {
        this.uncheckAll();
      }
      this.uncheckGroupCheckboxByValue(GROUP_NONE);
    } else if (event.currentTarget.value === GROUP_NONE) {
      this.uncheckAll();
      this.uncheckGroupCheckboxByValue(GROUP_ALL);
    } else {
      this.uncheckAllGroupCheckboxes();
    };

    this.updateCheckboxesCount();
  }

  setCount() {
    if (this.hasCountTarget) {
      const count = this.selectedCheckboxes.length;
      this.countTarget.innerHTML = `${count} selected`;
    }
  }

  checkAll() {
    this.checkboxTargets
      .filter(target => !target.checked)
      .forEach(target => target.checked = true)
  }

  uncheckAll() {
    this.checkboxTargets
      .filter(target => target.checked)
      .forEach(target => target.checked = false)
  }

  uncheckAllGroupCheckboxes() {
    this.groupCheckboxTargets
      .filter(target => target.checked)
      .forEach(target => target.checked = false)
  }

  updateCheckboxesCount() {
    this.setCount();
  }

  uncheckGroupCheckboxByValue(value) {
    this.groupCheckboxTargets.find(
      target => target.value == value
    ).checked = false;
  }

  get selectedCheckboxes() {
    return this.checkboxTargets.filter(
      target => target.checked
    );
  }
};
