import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["trackerForm"];

  switchProvider(event) {
    const provider = event.target.value

    this.trackerFormTargets.forEach(function(target) {
      if(target.dataset.tracker === provider) {
        target.classList.remove("d-none")
      } else {
        target.classList.add("d-none")
      }
    })
  }
}
