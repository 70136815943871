import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["client", "form"];

  mergeClient() {
    const selected = $(".merge-client-modal__select2").find(":selected");

    const preleadClientId = $("[data-prelead-client-id]").attr(
      "data-prelead-client-id"
    );
    const targetClientId = selected.attr("value");

    const action = "/clients/" + preleadClientId + "/merge/" + targetClientId;
    this.formTarget.action = action;

    this.formTarget.submit();
  }
}
