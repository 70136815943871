import { Controller } from "stimulus"


function getCleanedParams(formData) {
  let params = new URLSearchParams(formData);
  let keysForDel = [];
  params.forEach((v, k) => v == '' && keysForDel.push(k));
  keysForDel.forEach(k => params.delete(k));
  return params;
}

export default class extends Controller {
  static targets = ["form"]

  connect() {
    this.checkAutoscroll();
  }

  checkAutoscroll(){
    const queryString = window.location.search;

    if ( queryString.includes("autoscroll_to") ) {
      const urlParams = new URLSearchParams(queryString);
      const autoscroll_to = urlParams.get('autoscroll_to')

      if (autoscroll_to !== "" && (autoscroll_to === this.data.get("elementid"))) {
        document.getElementById(autoscroll_to).scrollIntoView(true);
      }
    }
  }

  updateFiltersWithAutoscroll() {
    // set hidden field value to elementid's value (form_element_id)
    // preparing to submit the form which will contain
    // &autoscroll_to=form_element_id
    document.getElementById("autoscroll_to").value = this.data.get("elementid")

    this.element.closest("form").addEventListener("submit", function(event) {
      const data = new FormData(event.target);
      const params = getCleanedParams(data)
      const url = "?" + params.toString();

      history.pushState({ turbo: true, url: url }, "_", url);
    })

    this.element.closest("form").requestSubmit()
  }

  updateFilters() {
    this.formTarget.addEventListener("submit", function(event) {
      const data = new FormData(event.target);
      const queryString = new URLSearchParams(data).toString();
      const url = "?" + queryString;
      history.pushState({ turbo: true, url: url }, "_", url);
    })
    this.formTarget.requestSubmit()
  }

  resetFilterField() {
    const filterId = this.data.get('elementid')
    const form = document.getElementById('filter-form')
    const elements = form.elements;

    const targets = Array.from(elements).filter(elem => elem.id.includes(filterId));

    targets.forEach(resetInput)

    function resetInput(element) {
      const tagname = element.tagName.toLowerCase();
      const type = element.type;

      if (tagname == 'select') return element.selectedIndex = 0
      if (tagname == 'input' && type == 'checkbox') return element.checked = false  
      return element.value = ''
    }

    const params = getCleanedParams(new FormData(form)).toString();
    const url = "?" + params.toString();
    history.pushState({ turbo: true, url }, "_", url);
  }

  updateDateRangeFilter(event) {
    this.formTarget.addEventListener("submit", function(event) {
      const urlParams = new URLSearchParams(window.location.search);
      const scopeValue = urlParams.get('scope') || ""
      const params = getCleanedParams(new FormData(event.target))
      const queryString = params.toString();
      const url = "?" + `scope=${scopeValue}&` + queryString;

      history.pushState({ turbo: true, url: url }, "_", url);
    })

    const datePicker = event.target;
    const dateCount = datePicker.value.split(" - ").length;
    const isDatePickerActive = datePicker.classList.contains("active");

    if (dateCount === 2) {
      this.formTarget.requestSubmit();
    }

    // calendar picker is not opened and the same date is selected
    if (dateCount === 1 && !isDatePickerActive) {
      datePicker.value = `${datePicker.value} - ${datePicker.value}`;
      this.formTarget.requestSubmit();
    }
  }

  reset(event) {
    event.preventDefault();
    this.formTarget.reset()
    const url = this.formTarget.getAttribute("action")
    window.location.replace(url)
  }
}

