import Flatpickr from "stimulus-flatpickr"

export default class extends Flatpickr {
  initialize() {
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      locale: {
        rangeSeparator: ' - '
      }
    }
  }
}
