import { Controller } from "stimulus"

export default class extends Controller {
  toggleFlexMenu() {
    const togglables = this.element.querySelectorAll(".sm-togglable");

    togglables.forEach(
      function(node, _index, _collection) {
        node.classList.toggle("sm:hidden");
      }
    );
  }
}
